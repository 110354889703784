.CardStructure {
    background-color: white;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
    display: inline-block;
    border-radius: 10px;
    overflow: hidden;
}


.CardStructure .ImgStructCard {
    background-color: brown;
    width: 60px;
    height: 60px;
    border-radius: 999px;
    margin: 20px 10px 20px 30px;
}

.CardStructure table td {
    vertical-align: middle;
}

.CardStructure .tdMidStructCard {
    min-width: 200px;
}

.CardStructure .TitleStructCard {
    font-family: 'Staatliches';
    font-size: 30px;
}

.CardStructure .MailStructCard {
    color: rgba(0, 0, 0, 0.6);
    margin-top: 5px;
}

.CardStructure table td:last-child {
    background-color: black;
    padding: 0 15px;
    background: rgb(224, 0, 0);
    background: -moz-linear-gradient(135deg, rgba(224, 0, 0, 1) 0%, rgba(250, 184, 16, 1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(224, 0, 0, 1) 0%, rgba(250, 184, 16, 1) 100%);
    background: linear-gradient(135deg, rgba(224, 0, 0, 1) 0%, rgba(250, 184, 16, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e00000', endColorstr='#fab810', GradientType=1);
    cursor: pointer;
    transition: padding 0.3s;
}

.CardStructure table td:last-child:hover {
    padding: 0 20px;
}


@media screen and (max-width: 420px) {
    .CardStructure .tdMidStructCard {
        min-width: 150px;
    }
}