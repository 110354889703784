#Profile {
    text-align: left;
    background-color: rgb(249, 249, 249);
}

#Profile #BlueBack {
    background-color: #0D2436;
    height: 300px;
    margin-bottom: -300px;
}

#Profile .TitleNameProfile {
    color: white;
    font-family: 'Staatliches';
    font-size: 30px;
    margin-top: 60px;
}

#Profile .TicketListProfileTitle {
    color: white;
    font-family: 'Staatliches';
    font-size: 30px;
    margin-top: 60px;
}

.TicketList .TicketElmList {
    margin-bottom: 20px;
}

.TicketList .TicketElmList:last-child {
    margin-bottom: 0;
}
#Profile .InfoProfileTitle {
    color: #0D2436;
    font-family: 'Staatliches';
    font-size: 30px;
    margin-top: 60px;
}

#Profile .InfoUserElm {
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
}

#Profile .InfoUserElm table td {
    vertical-align: middle;
}

#Profile .AvatarElmProfile {
    height: 80px;
    width: 80px;
    background-color: #0D2436;
    color: white;
    border-radius: 999px;
    text-align: center;
    font-size: 60px;
    font-family: 'Staatliches';
    padding-top: 33px;
    margin: 30px 30px;
}

#Profile .UsernameProfileInfo {
    color: #cd1619;
    margin: 2px 0 5px 0;
}

#Profile .InfoUserElm table {
    width: 100%;
}

#Profile .InfoUserElm table td:first-child{
    width: 140px;
}

#Profile .ButtonUpdateProfileInfo {
    background-color: #0D2436;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    padding: 10px 13px;
    display: inline-block;
    transform: background-color 0.3s;
}

#Profile .ButtonUpdateProfileInfo:hover {
    background-color: rgb(33, 76, 109);
}