.Body {
    background-color: rgb(250, 250, 250);
    padding-top: 20px;
    text-align: left;
    padding-bottom: 200px;
}

.SimpleContainer {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
}

.InfoUser {
    text-align: left;
    padding: 20px;
    font-size: 15px;
}

.TitleUser {
    font-family: 'Staatliches';
    font-size: 25px;
    line-height: 1em;
}

.TitleEvent {
    font-family: 'Staatliches';
    font-size: 30px;
    line-height: 1em;
    text-align: left;
    display: block;
    margin: 20px 0;
}

.QRCodeContain {
    text-align: left;
    padding: 30px;
    margin-bottom: 20px;
}

.TagCode {
    font-size: 30px;
    text-align: left;
    display: inline-block;
    padding: 15px;
    color: rgb(22, 144, 205);
    background-color: rgba(22, 144, 205, 0.1);
    border-radius: 5px;
    border: 1px solid rgb(22, 144, 205);
    font-weight: bold;
}