.ListEventPage {

}

.ListEventPage .BgFondListEvent {
    background: rgb(255,60,0);
    background: -moz-linear-gradient(45deg, rgba(255,60,0,1) 0%, rgba(137,0,255,1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(255,60,0,1) 0%,rgba(137,0,255,1) 100%);
    background: linear-gradient(45deg, rgba(255,60,0,1) 0%,rgba(137,0,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff3c00', endColorstr='#8900ff',GradientType=1 );
    height: 500px;
}

.ListEventPage .EventsContainer {
    text-align: left;
    margin-top: -420px;
    min-height: 60vh;
}

.ListEventPage .TitleEvents {
    font-family: 'Staatliches';
    font-size: 30px;
    color: white;
}

.ListEventPage .PannelContainerEventList {
    margin: 20px 0 0 0;
}

.ListEventPage .LoaderPannel {
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: white;
    padding: 30px 30px;
    border-radius: 5px;
    font-family: 'Staatliches';
    font-size: 20px;
}

.ListEventPage .EventListFull {
    margin-top: 70px;
}
.ListEventPage .EventListFull .TitleEventList {
    font-family: 'Staatliches';
    font-size: 30px;
    line-height: 1em;
}

.ListEventPage .EventListFull .AllFullEvents {
    padding-top: 30px;
}

.EventPannelList {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: white;
    padding: 25px 20px;
    overflow: hidden;
}
.EventPannelList .TitleEvent{
    font-family: 'Staatliches';
    font-size: 25px;
    line-height: 1em;
}

.EventPannelList .PannelListEventsElms {
    position: relative;
    margin-top: 20px;
}

.EventPannelList .ArrowEventList {
    background-repeat: no-repeat;
    background-position: center;
    height: 40px;
    width: 40px;
    border-radius: 999px;
    background-size: 25px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 0.3s;
    z-index: 2;
    background-color: white;
}
.EventPannelList .ArrowEventList:hover {
    height: 55px;
    width: 55px;
    background-size: 30px;
}

.EventPannelList .AllElmListEvents {
    margin: 0 80px;
}

.EventPannelList .ElmEventFocus {
    display: inline-block;
    z-index: 1;
    cursor: pointer;
}

.EventPannelList .CarouselEventList {
    text-align: left;
    transform: translateX(-50px);
    transition: transform 0.5s;
}

.EventFocusPick {
    color: black;
}

.EventFocusPick .ImgFocusEvent {
    height: 170px;
    width: 170px;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    transition: width 0.3s;
}
.EventFocusPick:hover .ImgFocusEvent {
    width: 190px;
}

@media only screen and (max-width: 767px) {
    .EventFocusPick .ImgFocusEvent {
        width: 30px;
    }
    .EventFocusPick:hover .ImgFocusEvent {
        width: 30px;
    }

    .EventFocusPick .ImgFocusEvent:hover {
        width: 200px;
    }
}

.EventFocusPick table td {
    vertical-align: top;
}

.EventFocusPick .DataFocus {
    padding: 0 0 0 10px;
}
.EventFocusPick .DataFocus .TitleDataFocus {
    font-family: 'Staatliches';
    font-size: 20px;
}

.EventFocusPick .DataFocus .AssosDataFocus {
    font-size: 14px;
    margin-top: 3px;
    color: rgba(0, 0, 0, 0.5);
}

.EventFocusPick .DataFocus .DateEvent {
    font-size: 13px;
    margin-top: 0px;
}

.EventFocusPick .DataFocus .TimeEvent {
    font-size: 18px;
    font-family: 'Staatliches';
    margin-top: 0px;
    margin-bottom: 10px;
}

.EventFocusPick .DataFocus .DayEventWeek {
    font-family: 'Staatliches';
}