#FrontViewEventFocus {

}

#FrontViewEventFocus .FrontBG {
    background-position: center;
    background-size: cover;
    min-height: 800px;
    padding: 100px 0;
}

#FrontViewEventFocus .WheelCross {
    height: 50px;
    width: 50px;
    background-color: white;
    border-radius: 999px;
    background-image: url("./YellowCross.png");
    background-position: center;
    background-size: 30px;
    background-repeat: no-repeat;
    transform: rotate(0deg);
    cursor: pointer;
    transition: transform 0.3s;
}

#FrontViewEventFocus .EventContainer {
    text-align: left;
    background-color: white;
    margin-top: 20px;
    border-radius: 15px;
    padding: 40px 20px;
    opacity: 1;
    transition: opacity 0.3s;
}

#FrontViewEventFocus .WheelIdAsso {
    height: 60px;
    width: 60px;
    background-color: brown;
    border-radius: 999px;
}

#FrontViewEventFocus table td{
    vertical-align: top;
    padding-right: 20px;
}

#FrontViewEventFocus table td .EventName{
    font-family: 'Staatliches';
    font-size: 40px;
    line-height: 1em;
}

#FrontViewEventFocus table td .EventAsso{
    font-size: 20px;
}

#FrontViewEventFocus .ItemFocus{
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 3px 7px;
    border-radius: 5px;
    margin: 10px 10px 0 0;
    vertical-align: top;
}

#FrontViewEventFocus .MiniPlaceFocus{
    font-family: "Staatliches";
    font-size: 20px;
    padding: 10px 7px;
}