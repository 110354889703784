@keyframes annimOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.FullSearch {
    position: absolute;
    z-index: 2;
    margin-left: 0px;
    background-color: white;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0 0 5px 5px;
    width: 90%;
    overflow: hidden;
    animation: annimOpacity 0.3s;
}

.elmSearched {
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    transition: all 0.3s;
}

@keyframes annimTd {
    0% {
        padding: 10px 0;
    }
    100% {
        padding: 15px 0;
    }
}

.elmSearched td {
    padding: 15px 0;
    animation: annimTd 0.3s;
}


.elmSearched td:first-child {
    width: 1%;
}

.elmSearched:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.MiniDate {
    display: inline-block;
    margin: 0 20px;
}

.elmEvent {
    margin-left: 20px;
}

.assoName {
    color: rgba(0, 0, 0, 0.6);
}