#Payement {
    background-color: rgb(24, 24, 24);
    border-radius: 20px;
    margin: 50px 0;
    text-align: left;
    padding: 30px 50px 30px 50px;
}

@media screen and (max-width: 500px) {
    #Payement {
        padding: 20px 15px 20px 15px;
    }
}

#Payement .TitlePayement {
    color: white;
    font-family: 'Staatliches';
    font-size: 30px;
    line-height: 1em;
}