.header {
    background-color: white;
    font-size: 20px;
    padding: 10px 25px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
}

.fakeheader {
    height: 60px;
}

.logo {
    height: 40px;
}
.divlogo {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.menu {
    width: 0;
    height: 0;
    vertical-align: middle;
    position: relative;
    top: 8px;
}

.black {
    display: none;
    background-color: black;
    position: fixed;
    z-index: 4;
    width: 100%;
    height: 100%;
}

@keyframes endBlack {
    0% {
        display: block !important;
        opacity: 0.5;
    }
    100% {
        display: block !important;
        opacity: 0;
    }
}

.blackOut {
    display: block;
    background-color: black;
    opacity: 0;
    position: fixed;
    z-index: 4;
    width: 100%;
    height: 100%;
    animation-name: endBlack !important;
    animation-duration: 0.3s;
}

@keyframes showBlack {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}

.blackActive {
    display: block !important;
    opacity: 0.5 !important;
    animation-name: showBlack;
    animation-duration: 0.3s;
}

.sideMenu {
    position: fixed;
    background-color: white;
    height: 100%;
    width: 70%;
    top: 0;
    left: -70%;
    z-index: 5;
    transition: left 0.3s;
}

.sideMenuActive {
    left: 0% !important;
}

.elmNav {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px 25px;
    text-align: left;
    font-size: 15px;
}
.elmNav a{
    color: rgba(0, 0, 0, 0.8);
}
.elmNav:last-child {
    border-bottom: none;
}

.titleMenu {
    font-size: 20px;
    padding: 25px 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.nameConnected {
    font-size: 18px;
    color: #cd1619;
}

.iconFix {
    position: relative;
    bottom: 3px;
    margin-right: 10px;
}