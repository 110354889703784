.searchContain {
    position: relative;
    vertical-align: left;
}
.search {
    display: block;
    margin: 50px 10px 0 0px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.05);
    outline: none;
    padding: 10px 5px;
    font-size: 15px;
}