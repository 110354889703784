.Register {
    background-repeat: no-repeat;
    background-position: top left;
    background-color: #ffecdf;
    position: relative;
}

.Register .CenterRegister {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}

@media screen and (max-height: 900px) {
    .Register .CenterRegister {
        position: inherit;
        top: 0;
        left: 0;
        right: 0;
        transform: translateY(0);
        padding: 50px 0;
    }
}

.Register .PannelRegister {
    text-align: left;
    background-color: white;
    border-radius: 5px;
    padding: 30px 25px;
}

.PannelRegister .TitlePannel {
    font-size: 25px;
    font-family: 'Staatliches';
    line-height: 1em;
}

.PannelRegister .AllInput {
    margin: 40px 0 10px 0;
}

.PannelRegister .inputRegister {
    height: 50px;
    padding: 0 10px;
    font-size: 20px;
    margin: 0 0 30px 0;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s;
}

.PannelRegister .inputRegister:focus {
    border-color: rgba(0, 0, 0, 0.5);
}

.PannelRegister .divInputRegister {
    display: inline-block;
    padding: 0 5px;
}

.PannelRegister .RegisterValidate {
    display: block;
    font-size: 20px;
    width: 100%;
    padding: 10px 0;
    border: none;
    color: white;
    outline: none;
    background-color: #cd1619;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.PannelRegister .RegisterValidate:hover {
    background-color: #720e10;
}

.PannelRegister .ErrorRegister {
    color: #cd1619;
    font-size: 20px;
    margin: 0 0 20px 0;
}