@keyframes ShowOffline {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0%);
    }
}

.Layer {
    position: fixed;
    bottom: 0;
    left: 100px;
    color: white;
    background-color: black;
    animation-name: ShowOffline;
    animation-duration: 0.3s;
    transform: translateY(0%);
    transition: transform 0.3s;
}

.msg {
    font-size: 15px;
    margin: 15px 25px 15px 15px;
    display: inline-block;
    vertical-align: middle;
}

.circle {
    background-color: rgb(205, 22, 25);
    vertical-align: middle;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 999px;
    margin-left: 25px;
}

.retry {
    display: inline-block;
    height: 44px;
    width: 44px;
    background-color: rgb(63, 63, 63);
    vertical-align: middle;
    font-size: 20px;
    padding: 12px;
}