html {
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto Condensed";
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* HEADER */

header .logo {
  max-height: 30px;
}

nav {
  background-color: white;
  text-align: left;
  padding: 0 40px;
}
nav .elm_nav {
  display: inline-block;
  vertical-align: middle;
}

nav .title{
  font-size: 25px;
  margin: 10px 0;
}

nav form {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 600px;
}
nav .search {
  margin: 0 0 0 0;
  height: 57px;
  border: none;
  padding: 0 20px;
  display: block;
  width: 100%;
}

nav .left_align_nav {
  text-align: right;
}

nav .left_align_nav .elm_nav_link {
  display: inline-block;
  margin: 0 0 0 20px;
  vertical-align: middle;
}

/* Main Page */
.Carousel #EventCarousel {
  height: 600px;
}

.Carousel .CarouselItem {
  height: 600px;
  background-color: rgb(241, 241, 241);
}

.Carousel .CarouselBlock {
  height: 100%;
}

.Carousel .NoShow {
  font-size: 15px;
  padding-top: 100px;
}

/* View Carousel */
.CarouselBgView {
  background-position: center;
  background-size: cover;
  height: 100%;
  padding-top: 30px;
  text-align: left;
}

.CarouselBgView .FirstCardPres {
  background-color: white;
  border-radius: 3px;
  padding: 20px 20px;
  text-align: left;
  display: block;
}

.CarouselBgView .CardTitle {
  font-family: "Staatliches";
  font-size: 50px;
  line-height: 1em;
}

.CarouselBgView .CardAsso {
  font-size: 25px;
  line-height: 1em;
  font-family: "Staatliches";
  padding: 20px 0 10px 20px;
}

.CarouselBgView .subCard {
  margin-top: 10px;
  padding: 0;
  text-align: left;
}

.CarouselBgView .MiniDateElm {
  display: inline-block;
  background-color: white;
  vertical-align: middle;
  color: black;
  border-radius: 5px;
  padding: 5px 7px;
}

.CarouselBgView .MiniTimeElm {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  background-color: white;
  color: black;
  border-radius: 5px;
  padding: 5px 7px;
}

.CarouselBgView .MiniPlaceElm {
  font-family: 'Staatliches';
  display: inline-block;
  background-color: white;
  vertical-align: middle;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 20px;
  padding: 12px 10px;
}

.CarouselBgView .buttonSee {
  display: inline-block;
  background-color: white;
  font-family: 'Staatliches';
  font-size: 20px;
  line-height: 1em;
  padding: 4px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0 0 0;
  transition: 0.3s;
  color: black;
}

.CarouselBgView .buttonSee:hover {
  padding: 4px 20px;
}

/* Minidate */
.MiniDate {
  font-size: 15px;
  text-align: center;
}

.MiniDate .topMiniDate {
  font-family: 'Staatliches';
}

.MiniDate .botMiniDate {
  margin-top: 0px;
}

/* MiniTime */
.MiniTime {
  text-align: center;
}

.MiniTime .topMiniTime {
  font-family: 'Staatliches';
  font-size: 18px;
}

.MiniTime .botMiniTime {
  margin-top: 0px;
  font-size: 12px;
}

/* EventList on Mainpage */

.MainPage .EventList {
  text-align: left;
  padding: 30px 10px;
}

.EventList h3 {
  font-family: 'Staatliches';
  font-size: 25px;
  margin: 0;
  padding: 0;
}

.EventList .NoShow {
  text-align: center;
}

.EventList a {
  color: black;
}

.EventList .EventListContainer {
  padding: 30px 0;
}

.EventList .EventListSeeAll {
  height: 150px;
  color: black;
  position: relative;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s;
}

.EventList .EventListSeeAll:hover {
  border-width: 10px;
  border-color: rgba(0, 0, 0, 1);
}

.EventList .EventListSeeAll .CenterSeeAll {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Staatliches';
  font-size: 20px;
}

/* EventCase */
.EventCase {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 20px;
  color: black;
}

.EventCase .ImgEventCase {
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

.EventCase .ImgEventCase .VoirImgEventCase {
  height: 150px;
  color: white;
  position: relative;
  opacity: 0;
  transition: opacity 0.3s;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  cursor: pointer;
}

.EventCase .ImgEventCase .VoirImgEventCase:hover {
  opacity: 1;
}

.EventCase .ImgEventCase .VoirImgEventCase .CenterEventVoir{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.EventCase .EventCaseDate {
  display: inline-block;
  vertical-align: middle;
}

.EventCase .description {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}
.EventCase .description div:first-child {
  font-family: 'Staatliches';
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  font-size: 20px;
  padding-top: 5px;
}

.EventCase .description div:last-child {
  margin-top: -5px;
}

footer {
  background-color: black;
  color: white;
  text-align: left;
  padding: 50px 0;
}

footer .footerElm {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 20px;
}

footer .titleFooter {
  font-size: 20px;
  font-family: 'Staatliches';
  margin-bottom: 20px;
}

footer .linkFooter {
  cursor: pointer;
  text-decoration: none;
  color: white;
}

footer .linkFooter:hover {
  text-decoration: underline;
  color: white;
}

footer .FooterLogo {
  margin-top: 20px;
}
footer .FooterLogoElm {
  display: inline-block;
  margin-right: 20px;
  vertical-align: center;
}
footer .FooterLogoTicket {
  height: 30px;
}
/*
footer .FooterLogoEpita {
  height: 40px;
}

footer .FooterLogoNeutrinos {
  height: 100px;
}
*/

/* Login */

.Login {
  text-align: left;
}

.Login .LoginBg {
  background: rgb(224,0,0);
  background: -moz-linear-gradient(45deg, rgba(224,0,0,1) 0%, rgba(250,184,16,1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(224,0,0,1) 0%,rgba(250,184,16,1) 100%);
  background: linear-gradient(45deg, rgba(224,0,0,1) 0%,rgba(250,184,16,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e00000', endColorstr='#fab810',GradientType=1 );
}

.Login .LoginCenter {
  transform: translateY(-50%);
}

.Login .CardLogin {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 20px 25px 30px 25px;
}

.Login .TitleCard {
  font-family: 'Staatliches';
  font-size: 30px;
  line-height: 1em;
}

.Field .InputField {
  width: 100%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  height: 45px;
  font-size: 20px;
  padding: 0 10px;
  margin-top: 5px;
  transition: border 0.3s;
}

.Field .InputField:focus {
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
}

.Field .InputName {
  font-size: 20px;
}

.Login .FieldElm {
  margin-top: 30px;
}

.Login .ButtonField {
  text-align: center;
  display: block;
  width: 100%;
  border: none;
  color: white;
  font-size: 20px;
  padding: 7px 0;
  border-radius: 5px;
  margin: 30px 0 0 0;
  cursor: pointer;
  transition: all 0.3s;
}

.Login .ButtonField:hover {
  padding: 15px 0;
  margin: 22px 0 -8px 0;
}

.Login .SubButton {
  margin-top: 20px;
}

.Login .SubButton .SubButtonElm {
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 7px 10px;
  margin-right: 20px;
  font-size: 15px;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.3s;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.Login .SubButton .SubButtonElm:hover {
  border-color: rgba(0, 0, 0, 0.5);
  color: black;
}

.Login .ErrorLogin {
  color: rgb(205, 22, 25);
  font-size: 20px;
  margin: 30px 0 0 0;
}