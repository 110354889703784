.CardPayement {
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    display: inline-block;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
    margin-right: 25px;
}

.CardPayement .TitleCardPayement {
    color: white;
    font-family: 'Staatliches';
    font-size: 20px;
}

.CardPayement table td {
    vertical-align: middle;
}

.CardPayement table td:first-child {
    min-width: 200px;
}

.CardPayement .ListContainPayement {
    margin: 10px 0;
}

.CardPayement .ComprendPayement {
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
    font-style: italic;
}

.CardPayement .ListPayementItem {
    color: white;
    line-height: 1em;
}

.CardPayement .BuyButtonPayement {
    color: black;
    background-color: white;
    font-family: "Staatliches";
    font-size: 15px;
    display: inline-block;
    padding: 3px 15px;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
}

.CardPayement .BuyButtonPayement .ArrowPayementButton {
    vertical-align: middle;
    display: inline-block;
    margin-left: 10px;
    margin-right: 0;
    transition: all 0.3s;
}

.CardPayement .BuyButtonPayement:hover .ArrowPayementButton {
    margin-left: 15px;
    margin-right: -5px;
}

.CardPayement .BuyButtonPayement a {
    color: black;
}

.CardPayement .PricePayement {
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
    font-family: "Staatliches";
    line-height: 1em;
    padding: 15px 10px;
    border-radius: 5px;
    font-size: 50px;
}