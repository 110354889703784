#EventError {
  background: rgb(224,0,0);
  background: -moz-linear-gradient(45deg, rgba(224,0,0,1) 0%, rgba(250,184,16,1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(224,0,0,1) 0%,rgba(250,184,16,1) 100%);
  background: linear-gradient(45deg, rgba(224,0,0,1) 0%,rgba(250,184,16,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e00000', endColorstr='#fab810',GradientType=1 );
  color: white;
  padding: 100px 10px;
}

#EventError h2 {
    font-family: 'Staatliches';
    font-size: 40px;
}

#EventError .LinkError {
    color: black;
    background-color: white;
    border-radius: 5px;
    padding: 10px 15px;
    display: inline-block;
    font-size: 15px;
}
#EventError .LinkError:hover {
    background-color: rgba(255, 255, 255, 0.75);

}

#EventFocus {
    background-color: rgb(240, 240, 240);
}

#EventFocus .DescEvent {
    text-align: left;
    margin: 0 0 50px 0;
}

#EventFocus .StructureEventFocus {
    text-align: left;
    margin: 0 0 100px 0;
}
#EventFocus .TitleStructureEventFocus {
    font-family: 'Staatliches';
    font-size: 30px;
    margin-bottom: 20px;
}